import clsx from 'clsx'

const Button = ({ href, size = 'xl', className, children, ...props }) => {
  const style = size === 'sm' ? 'px-2 h-10 rounded-lg' : 'px-10 h-12 rounded-lg'

  return href ? (
    <a
      href={href}
      className={clsx(
        `focus:outline-none py-3 ${style} inline-flex justify-start items-center`,
        className
      )}
      {...props}
    >
      {children}
    </a>
  ) : (
    <button
      className={clsx(
        `focus:outline-none py-3 ${style} inline-flex justify-start items-center`,
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
