import Button from './Button'

const Pears = () => (
  <section className='w-full px-8 xs:px-16 antialiased py-3 lg:py-10'>
    <div className='container m-auto max-w-6xl flex flex-col-reverse md:flex-row justify-center items-center md:justify-start'>
      <div className='w-full my-6 relative flex justify-center'>
        <img alt='' className='w-full max-w-sm' src='/images/pears-bg.png' />
      </div>
      <div className='w-full flex flex-col md:flex-row pr-0 md:pr-16 lg:pr-0'>
        <div className='mb-10'>
          <h3 className='text-3xl font-semibold pt-6 pb-2'>
            Pear by Holepunch
          </h3>
          <p className='py-4 first:pt-0 text-muted'>
            Pear by Holepunch is a combined Peer-to-Peer (P2P) Runtime, Development & Deployment tool. Pear makes it possible to build, share and extend P2P applications using common Web and Mobile technology. Herein is everything needed to create unstoppable, zero-infrastructure P2P applications for Desktop, Terminal & Mobile.
          </p>
          <Button
            className='bg-buttonRegularBg border border-1 border-buttonRegularBorder mr-1 mt-1'
            href='https://pears.com/'
            target='_blank'
          >
            Learn More
          </Button>
          <Button
            className='bg-pear border border-1 border-pear mt-1 text-black'
            href='https://pears.com/'
            target='_blank'
          >
            Download Pear
          </Button>
        </div>
      </div>


    </div>
  </section>
)

export default Pears
