import { MENU_LINKS } from '../constants'

const Dropdown = ({
  isOpen,
  onMouseLeave = () => {}
}) => {
  const h = isOpen ? 'h-[35px]' : 'h-[0px]'
  return (
    <div className={`overflow-hidden transition-[height] duration-200 ease-in-out ${h}`}>
      <ul
        className='pt-3 flex justify-end'
        onMouseLeave={onMouseLeave}
      >
        {MENU_LINKS.map(
          ({ title, href }, idx) => (
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                className='text-muted mr-3'
                href={href}
                key={idx}
              >
                {title}
              </a>
            </li>
          )
        )}
      </ul>
    </div>
  )
}

export default Dropdown