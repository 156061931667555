const OurPlatform = () => (
  <section className='w-full px-8 xs:px-16 antialiased py-3 lg:py-10'>
    <div className='container m-auto max-w-6xl flex flex-col-reverse md:flex-row justify-center items-center md:justify-start'>

      <div className='w-full flex flex-col md:flex-row pr-0 md:pr-16 lg:pr-0'>
        <div className='mb-10'>
          <h3 className='text-3xl font-semibold pt-6 pb-2'>
            A Modular Stack
          </h3>
          <h6 className='text-orange text-lg'>
            Holepunch Tools
          </h6>
          <p className='py-4 first:pt-0 text-muted'>
            Holepunch helps you distribute your app to any user, on any platform, without needing to deal with ops. You can release, update, and scale an app directly from the laptop you developed it on. App updates are fast and efficient―users only download the parts of your app that have changed since the previous update.
          </p>
          <p className='py-4 first:pt-0 text-muted'>
            Peers who download your apps also re-host them for other peers, so the more users you have, the stronger your app becomes.
          </p>
        </div>
      </div>

      <div className='w-full my-6 relative flex justify-center'>
        <img alt='' className='w-full max-w-sm' src='/images/ourframework-img.png' />
      </div>

    </div>
  </section>
)

export default OurPlatform
