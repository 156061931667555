import { useEffect, useState } from 'react'

const useIntersectionObserver = (
  ref,
  options = { threshold: 0, rootMargin: '300px' }
) => {
  const [inView, setInView] = useState(true)

  const onIntersect = entries => {
    if (!io) return
    const isIntersecting = entries[entries.length - 1].isIntersecting
    setInView(isIntersecting)
  }

  const [io] = useState(
    () => new window.IntersectionObserver(onIntersect, options)
  )

  useEffect(() => {
    if (!io) return
    if (ref.current) io.observe(ref.current)
    return () => io.disconnect()
  }, [ref, io])

  return inView
}

export default useIntersectionObserver
