import { Routes, Route } from "react-router-dom"

import Home from '../pages/home'
import Privacy from './privacy'
import Terms from './terms'

const Pages = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/terms' element={<Terms />} />
    </Routes>
  )
}

export default Pages
