const Hero = () => (
  <section className='w-full lg:py-32 py-24 px-8 antialiased'>
    <div className='container m-auto max-w-6xl'>
      <div className='md:w-1/2 w-full'>
        <h1 className='font-bold text-6xl mb-10'>The Peer-to-Peer Company</h1>
        <div className='text-muted'>Holepunch is a platform for creating apps that don’t use any servers whatsoever. No arbitrary limits just better apps that can be shipped instantly, directly to your users.</div>
      </div>
    </div>
  </section>
)

export default Hero
