import { useRef, useState } from 'react'

import { MENU_LINKS } from '../constants'
import useIntersectionObserver from '../hooks/use-intersection-observer'

import Dropdown from './Dropdown'
import MenuButton from './MenuButton'

const Links = () => (
  <div>
  {MENU_LINKS.map(
    ({title, href}, idx) => (
      <a
        target='_blank'
        rel='noreferrer'
        className='mx-3 text-muted'
        href={href}
        key={idx}
      >
        {title}
      </a>
    )
  )}
  </div>
)

const Header = () => {
  const header = useRef()
  const inView = useIntersectionObserver(header)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSticky, setIsOpenSticky] = useState(false)
  const style = inView ? '-translate-y-20' : '-translate-y-0'

  return (
    <>
      <header ref={header} className='px-6 sm:px-16 z-50'>
        <div className='container m-auto max-w-6xl py-3 md:py-6 flex justify-between'>
          <a href='/'>
            <img
              className='md:w-[200px] w-[130px]'
              alt='Holepunch'
              src={`images/holepunch-logo.svg?refresh=1`}
            />
          </a>
          <div className='md:block hidden flex items-center'>
            <Links />
          </div>
          <div className='md:hidden flex items-center'>
            <MenuButton onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>
        {inView && <Dropdown isOpen={isOpen}/>}
      </header>
      <header
        className={`px-6 sm:px-16 py-3 z-50 fixed top-0 left-0 w-full backdrop-blur-xl transition group-main-hover:delay-200 duration-300 ease-in-out ${style}`}
      >
        <div className='flex justify-between items-center'>
          <a href='/'>
            <img
              className='w-[90px]'
              alt='Holepunch'
              src={`images/holepunch-logo.svg?refresh=1`}
            />
          </a>
          <div className='md:block hidden'>
            <Links />
          </div>
          <div className='md:hidden block'>
            <MenuButton onClick={() => setIsOpenSticky(!isOpenSticky)} />
          </div>
        </div>
        <Dropdown isOpen={!inView && isOpenSticky}/>
      </header>

    </>
  )
}

export default Header
