export const MENU_LINKS = [{
  title: 'Docs',
  href: 'https://docs.holepunch.to/'
}, {
  title: 'Twitter',
  href: 'https://twitter.com/holepunch_to'
}, {
  title: 'Contact Us',
  href: 'mailto:hello@holepunch.to',
}, {
  title: 'Join Us',
  href: 'https://holepunch.recruitee.com/'
}]
