import Layout from '../components/Layout'
import Hero from '../components/Hero'
import BuildingBlocks from '../components/BuildingBlocks'
import OurPlatform from '../components/OurPlatform'
import OurApps from '../components/OurApps'
import Background from '../components/Background'
import Pears from '../components/Pears'
import DownloadBanner from '../components/DownloadBanner'

const Home = () => (
  <>
    <Background />
    <Layout>
      <Hero />
      <BuildingBlocks />
      <OurPlatform />
      <Pears />
      <OurApps />
      <DownloadBanner />
    </Layout>
  </>
)

export default Home
