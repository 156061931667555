
const MenuButton = ({ onClick }) => {
  return (
    <div onClick={onClick} className='cursor-pointer'>
      <svg class='fill-gray-400' width="1.2em" height="1.2em" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"><path d="M0 15.8334H19V13.7223H0V15.8334ZM0 10.5556H19V8.44453H0V10.5556ZM0 3.16675V5.27786H19V3.16675H0Z"></path></svg>
    </div>
  )
}

export default MenuButton
