import { useState } from 'react'

import Button from './Button'
import CloseImage from '../assets/times.svg'
import LogoImage from '../assets/pear.svg'

const DownloadBanner = () => {
  const [show, setShow] = useState(true)
  if (!show) {
    return null
  }
  return (
    <div className='flex fixed bottom-0 left-0 w-full justify-center lg:mb-6 mb-3 z-10'>
      <div className='p-1 mx-3 rounded-lg p-px bg-banner-grad shadow-[0_0px_15px_0px_rgba(156,114,90,0.2)]'>
        <div className='flex justify-center items-center gap-3 p-3 px-6 rounded-lg bg-bannerBg'>
          <img alt='Keet' className='logo' width={20} height={20} src={LogoImage} />
          <div className='text-bannertext lg:text-base text-sm'>
            Introducing Pear - a combined Peer-to-peer (P2P) Runtime, Development & Deployment tool.
          </div>
          <div className='lg:mx-4 mx-0'>
            <Button
              size='sm'
              className='bg-pear border border-1 border-pear mt-1 text-black text-sm px-4'
              href='https://pears.com/'
              target='_blank'
            >
              Download Pear
            </Button>
          </div>
          <button onClick={() => { setShow(false) }}>
            <img alt='Close' width={20} height={20} src={CloseImage} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DownloadBanner
