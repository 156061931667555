import Button from './Button'

const OurApps = () => (
  <section className='w-full px-8 xs:px-16 antialiased bg-apps-texture bg-center bg-cover py-3 lg:py-10'>
    <div className='container m-auto max-w-6xl flex flex-col-reverse md:flex-row justify-center items-center md:justify-start'>

      <div className='w-full my-6 relative flex justify-center'>
        <img alt='Building blocks' className='w-full max-w-sm' src='/images/ourapps-img.png' />
      </div>

      <div className='w-full flex flex-col md:flex-row pr-0 md:pr-16 lg:pr-0'>
        <div className='mb-10'>
          <h3 className='text-3xl font-semibold pt-6 pb-2'>
            Our Apps
          </h3>
          <h6 className='text-aqua text-lg'>
            Private & Encrypted
          </h6>
          <p className='py-4 first:pt-0 text-muted'>
            Keet only shares end-to-end encrypted data between participants in your calls. Without middlemen, third-parties, or servers, there’s nobody left who can snoop or leak data. Keet video calls operate over direct connections between you and your friends, without using servers that can throttle your performance. You'll notice the difference. 
          </p>
          <Button
            className='bg-buttonRegularBg border border-1 border-buttonRegularBorder mr-1 mt-1'
            href='http://docs.holepunch.to/'
            target='_blank'
          >
            Learn More
          </Button>
          <Button
            className='bg-danger border border-1 border-danger mt-1'
            href='https://keet.io/'
            target='_blank'
          >
            Get Keet Beta
          </Button>
        </div>
      </div>

    </div>
  </section>
)


export default OurApps
