import Button from './Button'

const BuildingBlocks = () => (
  <section className='w-full px-8 xs:px-16 antialiased bg-blocks-texture bg-center bg-cover py-3 lg:py-10'>
    <div className='container m-auto max-w-6xl flex flex-col-reverse md:flex-row justify-center items-center md:justify-start'>

      <div className='w-full my-6 relative flex justify-center'>
        <img alt='Building blocks' className='w-full max-w-sm' src='/images/blocks-img.png' />
      </div>

      <div className='w-full flex flex-col md:flex-row pr-0 md:pr-16 lg:pr-0'>
        <div className='mb-10'>
          <h3 className='text-3xl font-semibold pt-6 pb-2'>
            Building Blocks
          </h3>
          <h6 className='text-aqua text-lg'>Introducing Hypercore</h6>
          <p className='py-4 first:pt-0 text-muted'>
            Holepunch provides a collection of *small Javascript modules* that can be combined to create an unlimited variety of P2P apps, from VPNs to communications tools like Keet. Mix and match them how you like. Since it's all just Javascript, developing a Holepunch app is as easy as building a web app.
          </p>
          <p className='py-4 first:pt-0 text-muted'>
            Focus on the parts of your app that matter, not on how to get data from A to B.
          </p>
          <Button
            className='bg-buttonRegularBg border border-1 border-buttonRegularBorder'
            href='http://docs.holepunch.to/'
            target='_blank'
          >
            Learn More
          </Button>
        </div>
      </div>

    </div>
  </section>
)

export default BuildingBlocks
