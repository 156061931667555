import Header from '../components/Header'
import Content from '../components/Content'
import Footer from '../components/Footer'

const Layout = ({ children }) => (
  <>
    <Header />
    <Content>{children}</Content>
    <Footer />
  </>
)


export default Layout
